import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';
import Grid from '../components/Styles/Grid';
import Box from '../components/Styles/Box';
import Flex from '../components/Styles/Flex';
import Heading from '../components/Styles/Heading';
import Text from '../components/Styles/Text';
import List from '../components/List';
import ListItem from '../components/ListItem';
import Faqs from '../components/Faqs';
import Image from '../components/Image';

const WhyUsPage = (props) => {
  const { data, location } = props;
  return (
    <Layout>
      <SEO title="Boston Web Development" />
      <PageHeader
        heading='Why choose <span class="highlight">ALTdev</span> over another "Agency"'
        subHeading='There are a lot of "Agencies" out there, but don&apos;t be fooled by the hype. You really do get what you pay for. Lets find out why ALTdev is different than those other guys and why their "Agency" is in quotes.'
        maxWidth={960}
        margin="auto"
      />

      <Section>
        <Grid
          gridTemplateColumns="repeat(2, 1fr)"
          gridGap={[2, 3]}
          alignItems="center"
          justifyItems="start"
        >
          <Box>
            <Heading as="h2">Ready to Super Charge your website?</Heading>
            <Text>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam
              tempore quas voluptatibus, fuga enim exercitationem! Laudantium
              voluptatum, at ducimus laboriosam officia perspiciatis rerum ipsam
              iure quae illum adipisci labore non.
            </Text>
            <List>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum, at ducimus laboriosam officia
                perspiciatis rerum ipsam iure quae illum adipisci labore non.
              </ListItem>
              <ListItem>
                Quam tempore quas voluptatibus, fuga enim exercitationem!
                Laudantium voluptatum.
              </ListItem>
            </List>
          </Box>
          <Box width="100%">
            <Image
              image={data.rocketDeveloper.childImageSharp.fluid}
              imageAltText="test"
              imageTitle="Test"
            />
          </Box>
        </Grid>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    rocketDeveloper: file(relativePath: { eq: "rocket-developer.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default WhyUsPage;
